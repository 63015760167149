<template>
    <el-dialog
            :title="title"
            :visible.sync="createDialogVisible"
            width="800px"
            :close-on-click-modal="false"
            :append-to-body="true"
            v-drag-dialog
            @close="close">
        <div class="pageContainer">
            <el-form label-position="right"
                     :model="formData"
                     :rules="rules"
                     ref="ProductForm"
                     label-width="100px"
                     element-loading-background="rgba(0, 0, 0, 0.8)">
                <el-form-item label="商品名称" prop="name">
                    <el-input v-model="formData.name" placeholder="请输入商品名称"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="商品价格" prop="price">
                            <el-input-number v-model="formData.price"
                                             class="numberInput"
                                             style="width: 100%;text-align: left"
                                             :prefix="2"
                                             :controls="false"
                                             placeholder="请输入商品价格"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="商品类型" prop="type">
                            <el-select v-model="formData.type" placeholder="请选择商品类型" class="widthCover">
                                <el-option v-for="item in PRODUCT_TYPE"
                                           :key="item.key"
                                           :value="item.key"
                                           :label="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开放时间" prop="openTime">
                            <el-date-picker v-model="formData.openTime"
                                            style="width: 100%"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            placeholder="选择拍摄时间"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="商品标签" prop="tag">
                            <el-input v-model="formData.tag" placeholder="多个类型用英文逗号隔开"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="供应商" prop="supplierId">
                            <el-select v-model="formData.supplierId"
                                       remote filterable
                                       :remote-method="tryGetSupplier"
                                       placeholder="请选择供应商" class="widthCover">
                                <el-option v-for="item in supplierList"
                                           :key="item.id"
                                           :value="item.id"
                                           :label="item.name"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="商品评分" prop="score">
                            <el-input-number v-model="formData.score" placeholder="请输入评分"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="商品描述" prop="description">
                            <el-input type="textarea"
                                      class="widthCover"
                                      :maxlength="200"
                                      v-model="formData.description"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="商品封面" prop="cover">
                            <img :src="formData.cover" @click="showImg(formData.cover, '商品封面')" class="smallImg" alt="">
                            <file-upload-btn v-if="createDialogVisible" @uploadSuccess="handleUploadCover"></file-upload-btn>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row type="flex" justify="center">
                <el-button type="primary" @click="trySubmit" :loading="addingLoading">确认提交</el-button>
            </el-row>
        </div>
    </el-dialog>
</template>

<script>
  import {mapActions} from 'vuex'
  import FileUploadBtn from 'modules/components/fileUploadBtn/index.vue'
  import {rules, resetProduct, execute, PRODUCT_TYPE} from '../option'

  export default {
    name: 'addOrEditProduct',
    components: {
      FileUploadBtn
    },
    data() {
      return {
        PRODUCT_TYPE,
        optType: 'add',
        createDialogVisible: false,
        callback: null,
        formData: resetProduct(),
        rules,
        addingLoading: false,
        supplierList: []
      }
    },
    computed: {
      title() {
        return this.optType === 'add' ? '新增商品' : '编辑商品'
      }
    },
    created() {
      this.tryGetSupplier('')
    },
    methods: {
      ...mapActions(['saveProduct', 'getProductById', 'updateProduct', 'getSupplierList']),
      show(options) {
        this.optType = options.optType
        this.callback = options.callback
        if (this.optType === 'edit') {
          this.tryGetProductById(options.detail.id)
          // this.formData = options.detail
        } else {
          this.formData = resetProduct()
        }
        this.createDialogVisible = true
        this.$nextTick(() => {
          this.$refs.ProductForm.clearValidate()
        })
      },
      close() {
        this.createDialogVisible = false
      },
      showImg(imgUrl, title) {
        execute('showImg', {
          imgUrl,
          title
        })
      },
      handleUploadCover(url) {
        this.formData.cover = url
      },
      tryGetProductById(id) {
        this.getProductById(id).then(res => {
          this.formData = res || this.formData
        })
      },
      tryUpdateProduct() {
        this.addingLoading = true
        this.updateProduct(this.formData).then(() => {
          this.$message.success('操作成功')
          this.callback && this.callback()
          this.close()
        }).finally(() => {
          this.addingLoading = false
        })
      },
      trySubmit() {
        if (this.addingLoading) {
          return
        }
        this.$refs.ProductForm.validate((valid) => {
          if (valid) {
            const api = this.optType === 'add' ? this.trySaveProduct : this.tryUpdateProduct
            api()
          }
        })
      },
      trySaveProduct() {
        this.addingLoading = true
        this.saveProduct(this.formData).then(() => {
          this.$message.success('提交成功')
          this.resetProduct()
          this.callback && this.callback()
          this.close()
        }).finally(() => {
          this.addingLoading = false
        })
      },
      tryGetSupplier(keyword) {
        this.getSupplierList({
          page: 1,
          size: 30,
          name: keyword
        }).then(res => {
          const { recordList } = res
          this.supplierList = recordList
        })
      },
      resetProduct() {
        this.formData = resetProduct()
      }
    }
  }
</script>
<style lang="scss">
    .numberInput {
        width: 100%;

        .el-input {
            .el-input__inner {
                text-align: left;
            }
        }
    }
</style>
<style scoped lang="scss">
    @import "../../../scss/elementVar";

    .pageContainer {
        padding: 20px;
    }

    .tagWrap {
        margin-bottom: 20px;
    }

    .uploadBtn {
        padding: 30px 0;
        width: 150px;
        height: 100px;
        box-sizing: border-box;
        text-align: center;
        color: $--color-info;
        border: 2px dashed $--color-info;
        opacity: 0.7;
        border-radius: 4px;
        transition: all linear 100ms;
    }

    .uploadBtn:hover {
        opacity: 1;
    }

    .uploadBtnIcon {
        font-weight: bold;
        font-size: 30px;
    }

    .uploadBtnText {
        line-height: 10px;
        font-size: 14px;
    }

    .imgContainer {
        float: left;
        margin: 0 10px 10px 0;
    }

    .smallImg {
        width: 40px;
    }
    .widthCover{
        width: 100%;
    }
</style>
